import React from 'react'
import style from "scss/pages/projects/materica.module.scss"
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import HeaderCase from 'blocks/case/headerCase'
import SectionHalfIntroduction from 'blocks/case/sectionHalfIntroduction'
import SectionHeadingFullWidth from 'blocks/case/sectionHeadingFullWidth'
import ParagraphLine from 'components/paragraphLine'
import SectionTextImageFullWidth from 'blocks/case/sectionTextImageFullWidth'
import Image from 'components/image'
import SectionSlider from 'blocks/case/sectionSlider'
import SectionDark from 'components/sectionDark'
import FooterCase from 'blocks/case/footerCase'

class Materica extends React.Component {
  render() {

    const { location, data } = this.props

    const imagePath = '/img/projects/materica/'

    const headerInfo = {
      data: "22.08.2019",
      color: "#000000",
      logoUrl: imagePath+'logo_materica.svg',
      image: imagePath+'bg_materica.jpg',
      title: "Da boutique artigianale a brand di design",
      tags: ["UI-UX DESIGN","BRAND IDENTITY","PROJECT MANAGEMENT","SOCIAL MEDIA STRATEGY","CONTENT CREATION","LEAD GENERATION","WEB DEVELOPMENT"]
    }

    const slideImages = ['/img/screenshot/materica1.jpg','/img/screenshot/materica2.jpg','/img/screenshot/materica3.jpg','/img/screenshot/materica4.jpg']

    return(
      <div>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Materica" description={'Sviluppa con noi la strategia digitale del tuo brand di arredamento, design. Creiamo campagne digitali di successo e contenuti emozionanti. Lasciati stupire.'} />

          <HeaderCase data={headerInfo} />

          <SectionHalfIntroduction>
            <h2>Da boutique artigianale a brand di design</h2>
            <p>Materica è una luxury boutique che realizza finiture metalliche di pregio. Attraverso la metallizzazione e la verniciatura, Materica trasforma le materie prime per esaltarle o dargli una nuova luce.
              <br/><br/>La richiesta è stata quella di creare una presenza digitale significativa partendo da zero per aumentare la notorietà del brand tra gli addetti ai lavori.</p>
          </SectionHalfIntroduction>

          <SectionTextImageFullWidth textPosition={'left'} padding={false} imageFade={false}>
            <div className={style.page__secondImage} style={{ marginRight: 'auto' }} >
              <img src={imagePath+'video-materica.png'} />
            </div>
            <ParagraphLine>
              <h2 data-title>Eleganza e raffinatezza veicolata attraverso il nuovo sito web</h2>
              <p>Materica è luce, colore e materia. Attraverso foto, video che esaltassero i giochi di movimento e luce tipici delle finiture materiche, abbiamo lasciato che le finiture parlassero da sé in una homepage realizzata con video presentati a mosaico.</p>
            </ParagraphLine>
          </SectionTextImageFullWidth>

          <SectionTextImageFullWidth textPosition={'right'} padding={false} imageFade={false}>
            <div className={style.page__secondImage}>
              <img src={imagePath+'logo_sketch.png'} style={{ marginRight: 'auto' }} />
            </div>
            <ParagraphLine>
              <h2 data-title>Digital Identity</h2>
              <p>Partendo da questionari e un'analisi del contesto è stato definito lo stile di Materica indagando sullla filosofia, le credenze e la proposta di valore che insieme costituiscono l'identità del brand.</p>
            </ParagraphLine>
          </SectionTextImageFullWidth>

          <SectionDark imageSrc={imagePath+'bg_materica.jpg'} imagePosition={'top'}>

            <SectionTextImageFullWidth textPosition={'right'} padding={false} imageFade={false}>
              <img src={imagePath+'social_materica.png'} style={{ margin: 'auto' }} />
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>Social Strategy</h2>
                <p className={`has-color-primary-negative`}>Testi e immagini evocative per presentare le finiture Materica. Abbiamo costruito un feed che potesse raccontare la filosofia del brand Materica privilegiando il canale Instagram.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

            <SectionSlider slidesData={slideImages}>
              <h2 className={`has-color-primary-negative`}>Risultati</h2>
              <p className={`has-color-primary-negative`}>Grazie all’elevato carattere emozionale dei contenuti creati e alla loro sponsorizzazione, abbiamo raggiunto un aumento dell’engagement, dei contatti e il coinvolgimento di partner, blogger, designer.</p>
            </SectionSlider>

          </SectionDark>

          <FooterCase prevProject={'navigando'} nextProject={'ycj'} nextProjectColor={'#292929'} />

        </Layout>
      </div>

    )
  }
}

export default Materica
